.totalTabla {
    align-items: center;
    text-align: right;
    font-weight: bold;
}

.T {
    font-weight: bold;
}

.Totales {
    font-weight: bold;
    font-size: 20px;
}

.editable{
    background-color: #eeb364;
    width: 150px;
    color: #eeb364;
}