.cardP{
    height: 500px;
}

.cardP img{
    width: 100%;
    margin-bottom: 50px;
}

.boton{
    margin-top: 30px;
}

.contenidoPago{
    margin-left: auto;
    margin-right: auto;
    height: 75vh;
    width: 80%;
    margin-top: 9%;

}