.divPadre{
    height: 300px;
    position: relative;
}

.divHijo{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: ttranslate(-50%,-50%);
}

.spinner{
    width: 100px !important;
    height: 100px !important;
    border: 10px solid currentColor !important;
    border-right-color: transparent !important;
}