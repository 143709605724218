.ContenedorGrafica {

    align-items: center;
}

.pie{
    align-items: center;

    .graf{
        justify-content: center;
        padding-left: 100px;
        padding-top: 50px; 
    }
}

.custom-tooltip{
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #929191;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    .label{
        font-weight: bold;
        text-align: center;
        
    }
}