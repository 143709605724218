.datatable{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}


.cellAction{
    button{
        width: 50px;
        height: 40px;
        background-color: #5C636A;
    }
    
}