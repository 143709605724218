.titulo{
    align-items: center;
    font-weight: bold;
    text-align: center;
    font-size: 2em;
}
.shadows{
    box-shadow: 0px 1px 13px 4px #7D7A7A;
    width: 100%;
    /*height: 68vh;*/
}
.celda{
    text-align: center;
    height: 20px;
}
.tabla1{
    width: 50%;
}
input[type='number']{
    width: 80px;
}

.botonera .icono{
    margin-left: 250px;
    width: 50px;
}

#reporteP{
    background-color: white;
}

#encabezado{
    display: none;
}

.btn-group button{
    margin-right: 15px;
    margin-left: 15px;
    width: 100px;

}

.TH{
    font-weight: bolder;
}