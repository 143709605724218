.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
.estilo{
    padding-left: 2.5rem; 
    padding-right: 2.5rem;
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.avatar{
  border-radius: 50%;
}

.imagenAvatar{
  text-align: center;
}

.Card{
  border: 1px solid #525151;
  height: 27rem;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #1B3055;
}


.headerCard{
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  color: white;
  margin-bottom: 1rem;

}
.seccion{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.bodyCard{
  font-size: 15px;
  color: white;
}
.bodyCard ul li{
  /*list-style-type: disc;*/
  color: white;
  /*margin-left: 15px;*/
  text-align: center;
  padding-left: 0rem;
}

.radioB select{
  background-color: #1B3055;
}

/* Labels for checked inputs */
input:checked + label {
  color: white;
  background-color: #1B3055;
}

/* Radio element, when checked */
input[type="radio"]:checked {
  box-shadow: 0 0 0 3px #1B3055;
}