.video {
    position: relative;
  }
  .marca {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-family: Arial;
    font-weight: bold;
    font-size: 50px;
    color: rgba(63, 58, 58, 0.6);
    z-index: 500;
    text-shadow: 5px 5px 10px #9e9e9e, 4px 4px 19px #da1b1b;
  }
  
  .hit-the-floor {
    color: #fff;
    font-size: 7em;
    font-weight: bold;
    font-family: Helvetica;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
      0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
      0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
      0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
      0 20px 20px rgba(0, 0, 0, 0.15);
  }
  
  .hit-the-floor {
    text-align: center;
  }
  